<template>
  <v-card flat class="rounded-10">
    <v-card-title class="text-center py-5">
      <v-row align="center" justify="center">
        <v-card flat width="180" height="150" v-if="mdAndUp">
          <v-img
            :lazy-src="settings.loader"
            height="100"
            contain
            :src="settings.squareLogo"
          ></v-img>
          <v-card-actions class="d-flex">
            <h2
              class="fs-20 align-self-center mx-auto primary--text font-weight-bold"
            >
              {{ settings.SiteName }}
            </h2>
          </v-card-actions>
        </v-card>
        <v-icon x-large class="mx-5" color="primary" v-if="mdAndUp">
          mdi-arrow-left-right-bold
        </v-icon>
        <v-card flat width="180" height="150">
          <v-img
            :lazy-src="settings.loader"
            contain
            height="100"
            :src="connectApp.logo"
          ></v-img>
          <v-card-actions class="d-flex">
            <h2
              class="fs-20 align-self-center mx-auto primary--text font-weight-bold"
            >
              {{ connectApp.name }}
            </h2>
          </v-card-actions>
        </v-card>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row dense class="mb-1">
        <p class="text-center secondary--text col-md-8 mx-auto fs-20 py-5">
          Integration with {{ connectApp.name }} will have access to following
          functionality:
        </p>
        <ul class="col-md-6 secondary--text mx-auto fs-17">
          <li>Create SMS and MMS campaigns with {{ settings.SiteName }}.</li>
          <li>Send SMS or MMS notifications to your leads</li>
          <li>More features coming soon.</li>
        </ul>
        <v-col cols="12"></v-col>
        <v-sheet class="text-left secondary--text col-md-6 mx-auto py-5">
          <strong class="primary--text fs-18">Terms of Use </strong>
          <p class="fs-15">
            Fugiat esse velit cillum labore tempor culpa et qui enim in. Non
            aliqua non anim do nostrud proident. Veniam qui magna ad in dolore
            exercitation elit ea irure laboris laborum cillum cillum sit.
            Incididunt officia dolor tempor irure esse sunt deserunt eu id et
            tempor voluptate do nostrud.
          </p>
          <p class="fs-15">
            aliquip exercitation mollit reprehenderit mollit. Adipisicing ad
            labore eu aliqua in fugiat ea sit cillum. Ut deserunt esse sint
            incididunt eu magna magna qui qui ex occaecat veniam sunt laboris.
            Nisi ut ullamco dolore ipsum. Et dolore veniam veniam non eiusmod
            mollit.
          </p>
        </v-sheet>
        <v-sheet
          class="text-center secondary--text col-md-8 mx-auto fs-18 py-5"
        >
          <p>
            Integration of Twilio can be done on
            <strong>Campaigns > SMS/MMS Campaign > Settings</strong>.
          </p>
          <v-btn
            class="text-none"
            @click="
              $router.push({
                name: 'smsmms-settings'
              })
            "
            min-width="200"
            color="primary"
            large
          >
            Go to configuration
          </v-btn>
        </v-sheet>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data: () => ({
    isTwilioSignIn: false,
    twilioUser: null,
    confirmDisconnect: false
  }),
  computed: {
    ...mapGetters('app', ['connects', 'allowedConnects']),
    connectApp() {
      return this.connects.find((i) => i.id === 'twilio')
    },
    available() {
      return this.allowedConnects.includes(this.connectApp.id)
    }
  },
  mounted() {
    this.handleClientLoad()
  },
  methods: {
    handleSignInTwilio() {},
    handleSignOutTwilio() {},
    handleClientLoad() {}
  }
}
</script>

<style lang="scss" scoped>
#twilio-connect-button {
  background: url(https://www.twilio.com/bundles/connect-apps/img/connect-button.png);
  width: 130px;
  height: 34px;
  display: block;
  margin: 0 auto;
}
#twilio-connect-button:hover {
  background-position: 0 34px;
}
</style>
